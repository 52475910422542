/**
 * @author  [Tandy Alam]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com]
 * @create date 2021-01-24 18:13:22
 * @modify date 2021-01-24 18:13:22
 * @desc [description]
 */

import axios from "axios";
import headers from "@/services/headers";
// const API_URL = "https://dev-api.klinisia.id/doctor/consultation/"; //prod
const API_URL = "https://api.klinisia.id/doctor/"; //dev
//const API_URL = "https://demo-dev-api.klinisia.id/doctor/consultation/"; //demo

class doctorServices {
  async getProfile() {
    return await axios.get(API_URL + "profile", {
      headers: headers(),
    });
  }
}
export default new doctorServices();
