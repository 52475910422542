/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-24 21:28:38
 * @modify date 2021-01-24 21:28:38
 * @desc [description]
 */

import axios from "axios";
import headers from "@/services/headers";
// const API_URL = "https://dev-api.klinisia.id/"; //prod
const API_URL = "https://api.klinisia.id/"; //dev
//const API_URL = "https://demo-dev-api.klinisia.id/"; //demo

class GeneralServices {
  // Radiology Form
  async getMasterRadiology() {
    return await axios.get(API_URL + "doctor/radiology/master/radiology-form", {
      headers: headers(),
    });
  }
  async getMasterLaboratory() {
    return await axios.get(
      API_URL + "doctor/laboratory/master/laboratory-form",
      {
        headers: headers(),
      },
    );
  }

  //Dashboard
  async getDashboardData() {
    return await axios.get(API_URL + "doctor/analytic/dashboard", {
      headers: headers(),
    });
  }
  //Dashboard Consultation summary
  async getDashboardConsultationSummary() {
    return await axios.get(
      API_URL + "doctor/analytic/consultation-today-summary",
      {
        headers: headers(),
      },
    );
  }
  //Dashboard Consultation performance
  async getDashboardConsultationPerformance() {
    return await axios.get(
      API_URL + "doctor/analytic/consultation-performance",
    );
  }
  async getDashboardTraffic() {
    return await axios.get(API_URL + "doctor/analytic/today-yesterday-trend", {
      headers: headers(),
    });
  }
  async getPhotoProfile(uriParam) {
    return await axios.get(
      API_URL + "storage/file/profile-picture/" + uriParam,
      {
        headers: headers(),
      },
    );
  }
  async postVidcall(bodyParams) {
    return await axios.post(API_URL + "doctor/vcall/start", bodyParams, {
      headers: headers(),
    });
  }

  async getHospital() {
    return await axios.get(API_URL + "no-auth/hospital/all", {
      headers: headers(),
    });
  }
}

export default new GeneralServices();
