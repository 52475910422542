/**
 * @author [Mochamad Arifin] [Tandy Alam]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com]
 * @create date 2021-01-24 18:13:22
 * @modify date 2021-01-24 18:13:22
 * @desc [description]
 */

import axios from "axios";
import headers from "@/services/headers";
// const API_URL = "https://dev-api.klinisia.id/doctor/consultation/"; //prod
const API_URL = "https://api.klinisia.id/doctor/consultation/"; //dev
//const API_URL = "https://demo-dev-api.klinisia.id/doctor/consultation/"; //demo

class ConsultationServices {
  async Queue() {
    return await axios.get(API_URL + "without-appointment/queue", {
      headers: headers(),
    });
  }

  async takeQueue(bodyParams) {
    return await axios.post(
      API_URL + "without-appointment/take-queue",
      bodyParams,
      {
        headers: headers(),
      },
    );
  }
  async takeQueueWithApp(bodyParams) {
    return await axios.post(
      API_URL + "with-appointment/take-queue",
      bodyParams,
      {
        headers: headers(),
      },
    );
  }

  // Start Plan
  async getSugestion(uriParams) {
    return await axios.get(API_URL + "plan/suggestion/" + uriParams, {
      headers: headers(),
    });
  }

  async postSugestion(params) {
    return await axios.post(
      API_URL + "plan/suggestion/" + params.uriParam,
      params.bodyParams,
      {
        headers: headers(),
      },
    );
  }

  async getPrescription(uriParams) {
    return await axios.get(API_URL + "plan/prescription/" + uriParams, {
      headers: headers(),
    });
  }

  async postPrescription(params) {
    return await axios.post(
      API_URL + "plan/prescription/" + params.uriParam,
      params.bodyParams,
      {
        headers: headers(),
      },
    );
  }
  // End Plan

  //Consultation History
  async consultationHistory(params) {
    return await axios.post(
      API_URL + "consultation-history/" + params.param,
      params.body,
      {
        headers: headers(),
      },
    );
  }

  // Start Chat
  async startChat(params) {
    return await axios.post(
      API_URL + "start-chat/" + params.uriParam,
      params.bodyParams,
      {
        headers: headers(),
      },
    );
  }

  // Finish Chat
  async finishChat(uriParams) {
    return await axios.post(API_URL + "finish/" + uriParams, "", {
      headers: headers(),
    });
  }

  // Pasien Summary
  async patientSummary(uriParams) {
    return await axios.get(API_URL + "summary/patient/" + uriParams, {
      headers: headers(),
    });
  }

  // Doctor Summary
  async doctorSummary(uriParams) {
    return await axios.get(API_URL + "summary/doctor/" + uriParams, {
      headers: headers(),
    });
  }

  // Patient Question
  async summaryQuestion() {
    return await axios.get(API_URL + "summary-question/", {
      headers: headers(),
    });
  }

  // postSummary
  async postSummary(request) {
    return await axios.post(
      API_URL + "summary/" + request.cntConsultationId,
      request,
      {
        headers: headers(),
      },
    );
  }

  //  get Objective
  async getObjective(request) {
    return await axios.get(API_URL + "objective/" + request, {
      headers: headers(),
    });
  }

  // End Summary

  // Assesment
  async getDiagnosis(uriParams) {
    return await axios.get(API_URL + "diagnosis/" + uriParams, {
      headers: headers(),
    });
  }

  async postDiagnosis(params) {
    return await axios.post(
      API_URL + "diagnosis/" + params.uriParam,
      params.bodyParams,
      {
        headers: headers(),
      },
    );
  }
  // detail pasien
  async getDetail(uriParams) {
    return await axios.get(API_URL + "detail/" + uriParams, {
      headers: headers(),
    });
  }

  async getSearchIcdCode(uriParams) {
    return await axios.get(API_URL + "diagnosis/search-icd?q=" + uriParams, {
      headers: headers(),
    });
  }
  // End Assesment

  // Diagnostik
  async postDiagnostic(request) {
    return await axios.post(
      API_URL + "plan/diagnostic/" + request.uriParam,
      request.body,
      {
        headers: headers(),
      },
    );
  }
  async getDiagnostic(uriParams) {
    return await axios.get(API_URL + "plan/diagnostic/" + uriParams, {
      headers: headers(),
    });
  }

  // Laboratory

  async getLaboratory(uriParams) {
    return await axios.get(API_URL + "plan/laboratory/" + uriParams, {
      headers: headers(),
    });
  }
  async postLaboratory(request) {
    return await axios.post(
      API_URL + "plan/laboratory/" + request.uriParam,
      request.body,
      {
        headers: headers(),
      },
    );
  }
  //Video call
  async postRoomVidcall(params) {
    return await axios.post(
      API_URL +
        "request-video-call/" +
        params.cntConsultationId +
        "/" +
        params.roomId,
      {
        headers: headers(),
      },
    );
  }
}

export default new ConsultationServices();
