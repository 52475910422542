/** * @author [Mochamad Arifin] * @email [mochamad.arifin@dwidasa.com] * @create
date 2021-01-11 22:06:42 * @modify date 2021-01-11 22:06:42 * @desc
[description] */

<template>
  <v-row>
    <v-col cols="12">
      <v-row class="ml-15">
        <v-col cols="5" lg="4" class="mt-7">
          <p class="lblTotalPatient">Tanya Klinisia</p>
        </v-col>
      </v-row>
      <v-row class="ml-15">
        <v-col cols="3">
          <p class="lblPatient">Pertanyaan</p>
        </v-col>
        <v-col cols="3" class="mt-1 questionLabel">
          <p class="bgTotalPatient">{{ totalPatientList }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-divider class="bgColorDivider ml-5 mr-5"></v-divider>
      </v-row>

      <v-row class="mt-2 ml-12 mr-9">
        <v-col>
          <div>
            <v-list>
              <v-list-item-group v-model="selectedItem">
                <v-list-item style="
                        height: 120px;
                        padding: 25px;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                        border-bottom: 1px dotted" v-for="(question, key) in filteredPatient" :key="key"
                  @click="takeQuestion(question.frmQuestionId)">
                  <v-list-item-content>
                    <div>
                      <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
                    </div>

                    <v-list-item-title style="
                        font-family: Nunito;
                        color: #073059;
                        font-weight: 800;
                        font-size: 14px;
                        text-transform: capitalize;
                      " v-text="question.title"></v-list-item-title>
                    <v-list-item-subtitle class="mt-2 mb-2"
                      style="font-family: Nunito; font-size:10px;font-weight: 800; color: #073059;">Oleh {{
                        question.userName
                      }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="mt- mb-2"
                      style="font-family: Nunito; font-size:10px;font-weight: 800; color: #0089bd;"> {{
                        question.category.categoryName
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle style="font-family: Nunito; font-size:12px">{{ question.question }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <div class="mt-2 pl-3" style="font-family: Nunito;
                        color: #073059;
                        font-weight: 800;
                        font-size: 9px;
                        text-transform: capitalize;
                        ">
                    <p>
                      {{ question.countComment }} Balasan
                      <img src="@/assets/comment.png" style="float: right;" />
                    </p>
                    <p>
                      {{ question.createdOn | timeago }}
                      <img class src="@/assets/date.png" style="float:right; margin-left:5px" />
                    </p>
                  </div>
                </v-list-item>
              </v-list-item-group>
            </v-list>
  
            <b-pagination-nav
            align="right"
            class="pagination-component"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              :active-page="currentPage"
              @change="changePage"
              @input="handlePageClick">
            </b-pagination-nav>
            
          
          </div>
          
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import useSound from "vue-use-sound";
import Moment from "moment";
import Vue from "vue";

Vue.filter("timeago", function (value) {
  return Moment(value).locale("id").fromNow();
});

import notif from "@/assets/pristine-609.mp3";
import VueAudio from "vue-audio";
import QuestionServices from "@/services/question_services";

export default {
  data: () => ({
    secUserId: window.localStorage.getItem("secUserId"),
    file1: notif,
    statusPlay: false,
    allowed: false,
    tag: 2,
    di: false,
    nilaiID: "",
    pasien: [],
    selectedItem: 0,
    list: [],
    shorToken: window.localStorage.getItem("shortToken"),
    params: {
      frmQuestionId: "",
    },
    loading: false,  // Status loading saat memuat data baru
    page: 0,  // Halaman saat ini
    perPage: 20,  // Jumlah item per halaman
    reachedEnd: false,
    totalItems:0,
    currentPage: 1,
    totalPages: 1,
    pageSize:20
  }),
  components: {
    "vue-audio": VueAudio,
  },
  methods: {
    handlePageClick(page) {
      console.log("Halaman diklik:", page);
      this.currentPage =  page - 1;
      this.loadItems();
    },

    takeQuestion(value) {
      this.di = true;
      console.log("PICK Question " + JSON.stringify(value));
      this.params.frmQuestionId = value;
      this.$emit("clickedPatient", this.tag);
      this.$store
        .dispatch("question/postTakeQuestion", value)
        .then((response) => {

          this.di = false;
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {


            if (response.data.payload.comment.length > 0) {
              let arrSecUserId = [];
              response.data.payload.comment.forEach(element => {
                if(element.userTypeCode == "doctor"){
                  arrSecUserId.push(element.secUserId)
                }
              });

              console.log(arrSecUserId)
              console.log(this.secUserId)
              if (arrSecUserId.length > 0){
                if (arrSecUserId.includes(this.secUserId)) {
                  this.allowed = true;
                } else {
                  this.allowed = false;
                }
              }else{
                this.allowed = true;
              }

            } else {
              this.allowed = true;
            }

           

            window.localStorage.setItem(
              "frmQuestionId",
              response.data.payload.question.frmQuestionId
            );

            window.localStorage.setItem(
              "isClickQuestion",
              true
            );
            
            this.$store.dispatch("question/actalertStatus", true)
            this.$store.dispatch("question/actallowed", this.allowed)

          }
        });
      window.localStorage.setItem("frmQuestionId", value);
    },

    getListQuestion() {
      return this.listPatient;
    },

    loadItems() {
      console.log("page = " + this.currentPage);
      console.log("size = " + this.pageSize);
      console.log("size = " +  window.localStorage.getItem("page"));
      window.localStorage.setItem("page", this.currentPage);
      console.log("on loadItems " + "page = " + this.currentPage + " size = " + this.pageSize);
        QuestionServices.getQeustionList(this.currentPage, this.pageSize)
        .then((response) => {
          console.log("sample 2");
          this.$store.dispatch(
            "question/setPatientList",
            response.data.payload.content
          );
          this.totalPages = response.data.payload.totalPages;
          console.log("size ieu = " + response.data.payload.totalPages);
          // this.listPatient;
        })
        .catch((e) => {
          console.log("sample ada yg error", e);
          window.localStorage.removeItem("page");
        });
    },

    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    changePage(newPage) {
      this.currentPage = newPage - 1;
      this.loadItems();
    },
  },
  computed: {
    totalPatientList() {
      return this.listPatient.length;
    },
    listPatient: {
      get() {
        return this.$store.getters["question/getListQuestion"];
      },
      set() { },
    },
    filteredPatient() {
      return this.getListQuestion();
    },
  },
  mounted() {
    // this.listPatient;
    this.currentPage = 0;
    this.loadItems();
    // this.addScrollListener();
    // const listContainer = this.$refs.listContainer;
    // window.addEventListener('scroll', this.addScrollListener);
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1920px) {
  .questionLabel {
    margin-left: 35px;
  }
}

.v-list {
  height: 75vh;
  background: #edf4fb;
  overflow-y: auto;
}

.v-list-item--inactive {
  background: red;
  color: white;
} 

.bgTotalPatient {
  background: #a0cee8;
  font-size: 17px;
  width: 45px;
  color: #073059;
  text-align: center;
  border-radius: 50px;
}

.v-list-item {
  background-color: #edf4fb;
}

.lblTotalPatient {
  color: #2b92e4;
  font-size: 12px;
  margin: 0%;
  padding: 0%;
  font-family: Poppins;
  line-height: 1px;
}

.lblPatient {
  font-size: 20px;
  line-height: 33px;
  margin: 0%;
  padding: 0%;
  color: #073059;
  font-family: Nunito;
  font-weight: bold;
}

.titlePatientList {
  color: #073059;
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: Nunito;
}

.subtitlePatientList {
  color: #073059;
  font-size: 12px;
  font-family: Nunito;
}

.disable-patient div {
  color: rgba(7, 48, 89, 0.56);
}

@media only screen and (max-width: 1920px) {
  .v-list {
    height: 75vh;
    background: #edf4fb;
    overflow-y: auto;
  }

  .lblTotalPatient {
    color: #2b92e4;
    font-size: 12px;
    margin: 0%;
    padding: 0%;
    font-family: Poppins;
    line-height: 1px;
  }

  .lblPatient {
    font-size: 20px;
    line-height: 33px;
    margin: 0%;
    padding: 0%;
    color: #073059;
    font-family: Nunito;
    font-weight: bold;
  }

  .lblTotalPatient {
    color: #2b92e4;
    font-size: 12px;
    margin: 0%;
    padding: 0%;
    font-family: Poppins;
    line-height: 1px;
  }

  .titlePatientList {
    color: #073059;
    font-weight: 800;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-family: Nunito;
  }

  .subtitlePatientList {
    color: #073059;
    font-size: 10px;
    font-family: Nunito;
  }

  .pagination-component {
    color: #073059;
    margin-top: 10px; 
    align-items: start;
    font-size: 13px;
    font-family: Nunito;
  }
}
</style>
